:root {
  --headerHeight: 3em;
  --buttonColor: #0096FF;
  --buttonText: white;
  --highlightedButtonColor: white;
  --highlightedButtonText: #0096FF;
  --secondaryButtonText: #7393B3;
  --highlightedSecondaryButtonText: white;
  --selectedNavigationColor: green;
}

.App {
  height: 100vh;
  width: 100vw;
  margin: 0;
  /* background: linear-gradient(-45deg, #5f15f2, #ba0cf8, #e73c7e, #234fd5);
  animation: gradientBG 30s ease infinite;
  background-size: 250% 250%; */

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.AppContainer {
  height: 100%;
  width: 100%;
  margin: 0;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.Particles {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  pointer-events: none;
}

body, html {
  height: 100%;
  width: 100%;
  background: black;
  overflow: hidden;
  padding-bottom: env(safe-area-inset-bottom);
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes rainbowBG {
  0% {
    background: rgba(255, 0, 0, 0.1);
  }
  10% {
    background: rgba(255, 154, 0, 0.1);
  }
  20% {
    background: rgba(208, 222, 33, 0.1);
  }
  30% {
    background: rgba(79, 220, 74, 0.1);
  }
  40% {
    background: rgba(63, 218, 216, 0.1);
  }
  50% {
    background: rgba(47, 201, 226, 0.1);
  }
  60% {
    background: rgba(28, 127, 238, 0.1);
  }
  70% {
    background: rgba(95, 21, 242, 0.1);
  }
  80% {
    background: rgba(186, 12, 248, 0.1);
  }
  90% {
    background: rgba(251, 7, 217, 0.1);
  }
  100% {
    background: rgba(255, 0, 0, 0.1);
  }
}

@keyframes fadeIntoPosition { 
  0% {
    opacity: 0;
    top: 100%;
    height: 0%;
  }
  100% {
    opacity: 1;
    top: 0;
    height: 100%;
  }
}

@keyframes fadeOutOfPosition { 
  0% {
    opacity: 1;
    top: 0;
    height: 100%;
  }

  100% {
    opacity: 0;
    top: 100%;
    height: 0%;
  }
}

.pulse-once { animation: pulse-once 0.2s cubic-bezier(0.785, 0.135, 0.150, 0.860) both; }
@keyframes pulse-once {
  0% { transform: scale(1); }
  50% { transform: scale(0.8); }
  100% { transform: scale(1); }
}

.fade-in {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-out {
  animation: fadeOut 1s;
  -webkit-animation: fadeOut 1s;
  -moz-animation: fadeOut 1s;
  -o-animation: fadeOut 1s;
  -ms-animation: fadeOut 1s;
}
@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

a { 
  text-decoration: none; 
}

.hidden {
  display: none !important;
}

.TopView {
  z-index: 999;
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.ModalPopUpBlurBackground {
  height: 100%;
  width: 100%;
  position: absolute;
  backdrop-filter: blur(50px);
}

.HeaderContainer {
  height: var(--headerHeight);
  width: 100%;
  background: white;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  pointer-events: all;
}

.HeaderImage {
  height: 100%;
}

.HeaderNavigation {
  display: inline;
  margin: 0px 1.75vw;
  padding: 2px 0px;
  cursor: pointer;
  color: gray;
  font-weight: bold;
  font-size: 1em;
  border-bottom: medium solid transparent;
}

.SelectedHeaderNavigation {
  color: black;
  border-bottom-color: var(--selectedNavigationColor);
}

.BlurredBackground {
  height: calc(100% - var(--headerHeight));
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(5px);
  overflow-y: scroll;
}

.PageSection {
  height: calc(100% - var(--headerHeight));
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.PageHeader, .HomeHeader, .HomeSubheader {
  color: white;
  text-align: center;
}

.TermsOfService {
  height: 100%;
  width: 90%;
  margin: 0 5%;
  padding: 8px 0px;
  color: white;
  pointer-events: all;
  overflow-y: scroll;
  white-space: break-spaces;
}

.RegisterImage {
  width: 50%;
}

.RegistrationInput, .ProfileInput, .ProfileInputDisabled {
  margin: 8px;
  border-radius: 999px;
  border-color: transparent;
  font-size: x-large;
  text-indent: 8px;
  width: 100%;
  pointer-events: all;
}

.ProfileInputDisabled {
  color: white;
  background: rgba(169, 169, 169, 0.5);
  pointer-events: none;
}

.ProfileInput {
  color: black;
  background: white;
  pointer-events: all;
}

.RegisterForm {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.SecretInput {
  color: white;
  font-size: max(24px, 2vw);
  margin-left: auto;
  pointer-events: all;
}

.SecretInput:hover {
  color: gray;
}

.RegistrationTextContainer {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  white-space: nowrap;
}

.RegistrationTextButton, .ForgotPasswordButton, .SignOutPasswordButton {
  padding-left: 8px;
  font-weight: bold;
  color: var(--secondaryButtonText);
  pointer-events: all;
  cursor: default;
}

.RegistrationTextButton:hover, .ForgotPasswordButton:hover, .SignOutPasswordButton:hover {
  color: var(--highlightedSecondaryButtonText);
}

.ForgotPasswordButton, .SignOutPasswordButton {
  padding-left: 0px;
}

.RegisterButton, .SurveyButton, .DemoButton {
  width: 100%;
  border-radius: 999px;
  border-color: transparent;
  background: var(--buttonColor);
  font-size: x-large;
  color: var(--buttonText);
  pointer-events: all;
}

.RegisterButton:hover {
  background: var(--highlightedButtonColor);
  color: var(--highlightedButtonText);
}

.ProfileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.HomeRowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.HomeColumnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  margin-left: 12px;
  margin-right: 12px;
}

.SurveyButton {
  width: 75%;
  margin-bottom: 5vh;
}

.BetaSurvey {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  animation: fadeIntoPosition 0.65s ease-out;
  display: block;
}

.BetaSurveyHidden {
  position: absolute;
  height: 0%;
  width: 100%;
  top: 100%;
  pointer-events: none;
  animation: fadeOutOfPosition 0.65s ease-out;
  /* display: none !important; */
}

.HomeHeader {
  margin-bottom: 6px;
  letter-spacing: 0.5vmin;
  text-transform: uppercase;
}

.HomeSubheader {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.25vmin;
  font-weight: 300;
}

.HomeDescription {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: x-large;
}

.HomeDescriptionBody {
  font-weight: 300;
  color: lightgray;
}

.GalleryBackgroundVideo {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.GalleryInfo {
  color: darkgray;
  font-weight: bold;
  text-align: center;
}

.HiddenVideo {
  opacity: 0;
}

.DemoContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}

.DemoButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.DemoButton {
  width: 75%;
  margin-bottom: 5vh;
  border-width: 0;
}