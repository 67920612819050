.sd-root-modern {
    position: absolute;
    left: 0;
    top: 0%;
    height: 100%;
    width: 100%;
    overflow: scroll;
    padding-bottom: env(safe-area-inset-bottom);
    pointer-events: all;
    animation: fadeIntoPosition 0.5s ease-out;
}